import { useTranslation } from 'react-i18next';
import exnodeLogo from './../../img/exnode-logo.png';
import changeProLogo from './../../img/changepro-logo.svg';
import kursexpertLogo from './../../img/kursexpert.png';
import emonLogo from './../../img/emon.png';
import multiratesLogo  from './../../img/multirates.png';
import bits3Logo  from './../../img/bits3.png';
import trustpilotLogo  from './../../img/trustpilot.svg';
import './styles/advantages.css';
import './styles/partners.css';


export const PartnerLogos = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const bestChangeLink =
    currentLanguage === 'ru' ? 'https://www.bestchange.ru/' : 'https://www.bestchange.com/';
  
  const partners = [
    {
      href: bestChangeLink,
      imgSrc: 'https://www.bestchange.ru/bestchange.gif',
      width: 50,
      height: 100,
      alt: 'BestChange - Мониторинг обменников',
      title: 'Обменные пункты',
    },
    {
      href: 'https://exnode.ru/exchangers/exchanger-2398620',
      imgSrc: exnodeLogo,
      width: 50,
      height: 100,
      alt: 'Мониторинг обменников Exnode',
      title: 'Exnode - Мониторинг обменников',
    },
    {
      href: 'https://kurs.expert/ru/obmennik/dimmarex-com/feedbacks.html',
      imgSrc: kursexpertLogo,
      width: 50,
      height: 100,
      alt: 'Мониторинг обменников Kurs Expert',
      title: 'Обмен Биткоин, Киви, Яндекс, AdvCash',
    },
    {
      href: 'https://e-mon.ru/',
      imgSrc: emonLogo,
      width: 50,
      height: 100,
      alt: 'Выгодный курс обмена валют',
      title: 'Выгодный курс обмена валют',
    },
    {
      href: 'https://multirates.org/exchangers/dimmarex-com',
      imgSrc: multiratesLogo,
      width: 50,
      height: 100,
      alt: 'Мониторинг обменников Multirates',
      title: 'Мониторинг обменников Multirates',
    },
    {
      href: 'https://change.pro/exchangers/dimmar-exchange',
      imgSrc: changeProLogo,
      width: 50,
      height: 100,
      alt: 'Мониторинг обменников Change PRO',
      title: 'Change PRO - Мониторинг обменников',
    },
    {
      href: 'https://bits.media/exchanger/dimmar-exchange',
      imgSrc: bits3Logo,
      width: 50,
      height: 100,
      alt: 'BestExchangers.ru',
      title: 'Мониторинг обменников Bits.media',
    },
    {
      href: 'https://www.trustpilot.com/review/dimmarex.com',
      imgSrc: trustpilotLogo, 
      width: 0,
      height: 0,
      alt: 'Trustpilot',
      title: 'Trustpilot',
    },
  ];

  return (
    <div className='partners-container'>
      {partners.map((partner, index) => (
        <div key={index}>
          <a href={partner.href} target='_blank' rel='noreferrer'>
            {/* Если partner.imgSrc не пустая, рендерим <img>, 
                иначе (например Trustpilot) текст/логотип другой */}
            {partner.imgSrc ? (
              <img
                src={partner.imgSrc}
                alt={partner.alt}
                title={partner.title}
                width={partner.width}
                height={partner.height}
              />
            ) : (
              <span>{partner.title}</span>
            )}
          </a>
        </div>
      ))}
    </div>
  );
};


const Partners = () => {
  const { t } = useTranslation();
  return (
    <div className='partners-wrapper'>
      <div className='partners-title-container'>
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{ __html: t('main.partnersTitle') }}
        ></h2>
        <p>{t('main.partnersDesc')}</p>
      </div>

      <PartnerLogos />
    </div>
  );
};

export default Partners;
