import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import {
  isErc20Bep20WalletAddress,
  isTrc20WalletAddress,
  isBitcoinWalletAddress,
} from '../../utils/validator';

const WALLET_VALIDATORS = {
  'BEP-20 (Binance Smart Chain)': isErc20Bep20WalletAddress,
  'ERC-20 (Ethereum)': isErc20Bep20WalletAddress,
  'TRC-20 (TRON)': isTrc20WalletAddress,
  'Bitcoin Network': isBitcoinWalletAddress,
};

const CryptoRequisiteInput = ({ control, errors, networkLables, currency }) => {
  const { t } = useTranslation();

  const wallet = networkLables[currency.value];
  const showWallet = currency?.params?.includes('request_wallet_address');
  return (
    <>
      {showWallet && (
        <Controller
          name='wallet_address'
          id='crypto_wallet-input'
          control={control}
          rules={{
            required: t('main.fieldRequired'),
            validate: (value) =>
              WALLET_VALIDATORS[wallet](value) ||
              `${t('main.invalidWalletAddres')} ${wallet}`,
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <input
                {...field}
                required
                type='text'
                className={`user-data-input ${
                  error ? 'exchange-input-error' : ''
                }`}
                placeholder={`${t('main.walletPlaceholder')} ${wallet}`}
              />
              {errors.wallet_address && (
                <span className='error-form-message input-error'>
                  {errors.wallet_address.message}
                </span>
              )}{' '}
            </>
          )}
        />
      )}
    </>
  );
};

export default CryptoRequisiteInput;
