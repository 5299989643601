import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { isValidCardNumber, isValidPhoneNumber } from '../../utils/validator';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const BankRequisiteInput = ({ control, errors, currency }) => {
  const { t } = useTranslation();

  const showCard = currency?.params?.includes('request_card');
  const showPhone = currency?.params?.includes('request_phone');
  const showFullName = currency?.params?.includes('request_full_name');

  const formatCardNumber = (value) => {
    let onlyNums = value.replace(/\D/g, '');
    return onlyNums.replace(/(\d{4})(?=\d)/g, '$1 ');
  };

  return (
    <>
      {showCard && (
        <Controller
          name='card_number'
          control={control}
          rules={{
            required: t('main.fieldRequired'),
            validate: (value) =>
              isValidCardNumber(value) || t('main.invalidCardNumber'),
          }}
          render={({ field, fieldState: { error } }) => (
            <div className='input-container'>
              <input
                {...field}
                required
                type='text'
                className={`user-data-input ${
                  error ? 'exchange-input-error' : ''
                }`}
                placeholder={t('main.cardNumberPlaceholder')}
                onInput={(e) => {
                  e.target.value = formatCardNumber(e.target.value);
                }}
              />
              <label>{t('main.cardNumberPlaceholder')}</label>
              {errors.card_number && (
                <span className='error-form-message input-error'>
                  {errors.card_number.message}
                </span>
              )}
            </div>
          )}
        />
      )}

      {showPhone && (
        <Controller
          name='phone_number'
          control={control}
          rules={{
            required: t('main.fieldRequired'),
            validate: (value) =>
              isValidPhoneNumber(value) || t('main.invalidPhoneNumber'),
          }}
          render={({ field, fieldState: { error } }) => (
            <div className='input-container'>
              <PhoneInput
                country={'ru'}
                value={field.value || ''}
                onChange={field.onChange}
                placeholder={t('main.phoneNumberPlaceholder')}
                inputProps={{
                  name: field.name,
                  required: true,
                  className: `user-data-input ${
                    error ? 'exchange-input-error' : ''
                  }`,
                }}
                containerStyle={{ width: '100%' }}
                inputStyle={{
                  width: '100%',
                  paddingLeft: '48px',
                }}
                buttonStyle={{
                  left: '0px',
                }}
              />
              <label>{t('main.phoneNumberPlaceholder')}</label>
              {errors.phone_number && (
                <span className='error-form-message input-error'>
                  {errors.phone_number.message}
                </span>
              )}
            </div>
          )}
        />
      )}

      {showFullName && (
        <Controller
          name='full_name'
          control={control}
          rules={{
            required: t('main.fieldRequired'),
          }}
          render={({ field, fieldState: { error } }) => (
            <div className='input-container'>
              <input
                {...field}
                required
                type='text'
                className={`user-data-input ${
                  error ? 'exchange-input-error' : ''
                }`}
                placeholder={t('main.fullNamePlaceholder')}
              />
              <label>{t('main.fullNamePlaceholder')}</label>
              {errors.full_name && (
                <span className='error-form-message input-error'>
                  {errors.full_name.message}
                </span>
              )}
            </div>
          )}
        />
      )}
    </>
  );
};

export default BankRequisiteInput;
