import './../styles/franchise.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import rocketImg from '../img/franchise/rocket.svg';
import mapPointImg from '../img/franchise/mapPoint.svg';
import advIcon1 from '../img/franchise/advIcon1.jpg';
import advIcon2 from '../img/franchise/advIcon2.jpg';
import advIcon3 from '../img/franchise/advIcon3.jpg';
import advIcon4 from '../img/franchise/advIcon4.jpg';
import advIcon5 from '../img/franchise/advIcon5.jpg';
import advIcon6 from '../img/franchise/advIcon6.jpg';
import incIcon1 from '../img/franchise/incIcon1.jpg';
import incIcon2 from '../img/franchise/incIcon2.jpg';
import incIcon3 from '../img/franchise/incIcon3.jpg';
import ctaIcon1 from '../img/franchise/ctaIcon1.jpg';
import ctaIcon2 from '../img/franchise/ctaIcon2.jpg';
import ctaIcon3 from '../img/franchise/ctaIcon3.jpg';

import support1Icon from '../img/franchise/support1.svg';
import support2Icon from '../img/franchise/support2.svg';
import support3Icon from '../img/franchise/support3.svg';
import support4Icon from '../img/franchise/support4.svg';

import triangleImg from '../img/franchise/triangle.svg';
import manImg from '../img/franchise/man.png';

import { PartnerLogos } from './hompage/Partners';
import PopupMessage from './utils/PopupMessage';
import FeedbackForm from './utils/FeedBackForm';
import useFormSubmission from './hooks/useFormSubmission';
import { franchiseSubmit } from '../api/post';
import { SecondBlock } from './hompage/companyInfo';
import { Helmet } from 'react-helmet';

const FranchiseTitle = () => {
  const { t } = useTranslation();

  return (
    <div className='advantage-wrapper'>
      <div className='left-sidebar-background-img'></div>
      <div className='advantages-container b-white pre-wrap-text m-0'>
        <div className='map-background'>
          <h1
            className='block-title-h1'
            dangerouslySetInnerHTML={{ __html: t('franchise.title') }}
          ></h1>
          <p
            className='pink-b width-80'
            dangerouslySetInnerHTML={{ __html: t('franchise.desc') }}
          ></p>
          <a href='#orderForm' className='subscribe-button fh-btn top-50'>
            <img
              className='subscribe-button__img'
              src={rocketImg}
              alt='rocket'
            />
            {t('franchise.startOwnExchangeBtn')}
          </a>
        </div>
        <h2
          className='block-title-h2 top-50 bot-30'
          dangerouslySetInnerHTML={{
            __html: t('franchise.mission.title'),
          }}
        ></h2>
        <div className='content-block width-80 pink-b'>
          <p
            dangerouslySetInnerHTML={{
              __html: t('franchise.mission.desc'),
            }}
          ></p>
        </div>
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

const FranchiseAdvantage = () => {
  const { t } = useTranslation();

  const advantages = [
    {
      icon: advIcon1,
      title: t('franchise.advantage.item1.title'),
      desc: t('franchise.advantage.item1.desc'),
    },
    {
      icon: advIcon2,
      title: t('franchise.advantage.item2.title'),
      desc: t('franchise.advantage.item2.desc'),
    },
    {
      icon: advIcon3,
      title: t('franchise.advantage.item3.title'),
      desc: t('franchise.advantage.item3.desc'),
    },
    {
      icon: advIcon4,
      title: t('franchise.advantage.item4.title'),
      desc: t('franchise.advantage.item4.desc'),
    },
    {
      icon: advIcon5,
      title: t('franchise.advantage.item5.title'),
      desc: t('franchise.advantage.item5.desc'),
    },
    {
      icon: advIcon6,
      title: t('franchise.advantage.item6.title'),
      desc: t('franchise.advantage.item6.desc'),
    },
  ];

  return (
    <div className='welcome-container'>
      <div className='content-block-container'>
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{
            __html: t('franchise.advantage.title'),
          }}
        ></h2>
        <div className='advantages-grid'>
          {advantages.map((adv, i) => (
            <div key={i} className='advantage-card'>
              <div className='advantage-card__icon-wrap'>
                <img
                  className='advantage-card__icon'
                  src={adv.icon}
                  alt={`adv-icon-${i}`}
                />
              </div>
              <div className='advantage-card__text'>
                <h3 className='advantage-card__title'>{adv.title}</h3>
                <p className='advantage-card__desc'>{adv.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const OrderForm = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { isSuccess, isError, hideMessage, errorMessage, onSubmit } =
    useFormSubmission(
      franchiseSubmit,
      t('flash.succesSend'),
      t('flash.errorReview'),
      reset,
    );
  const fields = [
    {
      type: 'text',
      id: 'name',
      placeholder: t('reviews.namePlaceholder'),
      required: true,
    },
    {
      type: 'text',
      id: 'phone_number',
      placeholder: t('dashboard.phone_number'),
      required: true,
    },
    {
      type: 'text',
      id: 'city',
      placeholder: t('franchise.includedFeatures.city'),
      required: true,
    },
  ];
  return (
    <div className='contact-form'>
      <h2
        className='block-title-h2'
        dangerouslySetInnerHTML={{
          __html: t('franchise.includedFeatures.title4'),
        }}
      ></h2>
      <PopupMessage
        isSuccess={isSuccess}
        isError={isError}
        hideMessage={hideMessage}
        successMessage={t('flash.succesSend')}
        errorMessage={errorMessage || t('flash.error')}
      />

      <FeedbackForm
        fields={fields}
        submitLabel={t('franchise.includedFeatures.submitLabel')}
        onFormSubmit={onSubmit}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

const IncludedFeatures = () => {
  const { t } = useTranslation();
  const features = [
    {
      icon: incIcon1,
      title: t('franchise.includedFeatures.items.0.title'),
      desc: t('franchise.includedFeatures.items.0.desc'),
    },
    {
      icon: incIcon2,
      title: t('franchise.includedFeatures.items.1.title'),
      desc: t('franchise.includedFeatures.items.1.desc'),
    },
    {
      icon: incIcon3,
      title: t('franchise.includedFeatures.items.2.title'),
      desc: t('franchise.includedFeatures.items.2.desc'),
    },
  ];
  const steps = [
    {
      number: 1,
      title: t('franchise.includedFeatures.steps.0.title'),
      desc: t('franchise.includedFeatures.steps.0.desc'),
    },
    {
      number: 2,
      title: t('franchise.includedFeatures.steps.1.title'),
      desc: t('franchise.includedFeatures.steps.1.desc'),
    },
    {
      number: 3,
      title: t('franchise.includedFeatures.steps.2.title'),
      desc: t('franchise.includedFeatures.steps.2.desc'),
    },
    {
      number: 4,
      title: t('franchise.includedFeatures.steps.3.title'),
      desc: t('franchise.includedFeatures.steps.3.desc'),
    },
  ];

  return (
    <div className='advantage-wrapper'>
      <div className='left-sidebar-background-img'></div>
      <div className='advantages-container b-white pre-wrap-text m-0'>
        <h2
          className='block-title-h2 top-50 bot-30'
          dangerouslySetInnerHTML={{
            __html: t('franchise.includedFeatures.title1'),
          }}
        ></h2>
        <div className='bot-info-transfer '>
          {features.map((item, i) => (
            <div key={i} className='bot-info-transfer-item p-2'>
              <div className='bot-info-number-wrapper'>
                <img
                  src={item.icon}
                  alt={`icon-${i}`}
                  className='all-included-item__icon'
                />
              </div>
              <div>
                <h3
                  className='all-included-item__title blue-b'
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <p
                  className='all-included-item__desc'
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                />
              </div>
            </div>
          ))}
        </div>
        <h2
          className='block-title-h2 top-50 bot-30'
          dangerouslySetInnerHTML={{
            __html: t('franchise.includedFeatures.title2'),
          }}
        ></h2>
        <div className='width-80'>
          <PartnerLogos />
        </div>
        <h2
          className='block-title-h2 top-50 bot-30'
          dangerouslySetInnerHTML={{
            __html: t('franchise.includedFeatures.title3'),
          }}
        ></h2>
        <section className='steps-block'>
          <div className='steps-block__grid'>
            {steps.map((step, index) => (
              <div key={index} className='steps-block__card'>
                <h3 className='steps-block__card-title'>
                  {step.number}. {step.title}
                </h3>
                <p
                  className='steps-block__card-desc pink-b'
                  dangerouslySetInnerHTML={{ __html: step.desc }}
                />
              </div>
            ))}
          </div>
        </section>
        <div id='orderForm' className='flex-container width-60 top-50 bot-30'>
          <OrderForm />
        </div>
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

const CooperationTerms = () => {
  const { t } = useTranslation();

  const {
    profitLabel,
    depositLabel,
    monthlyLabel,
    cardLight,
    card1,
    card2,
    cardVip,
  } = t('franchise.packagesSection', {
    returnObjects: true,
  });

  const data = t('franchise.cooperationTerms', { returnObjects: true });

  const leftList1 = data.leftList1 || [];
  const leftList2 = data.leftList2 || [];
  const supportBlocks = data.supportBlocks || [];

  const icons = [support1Icon, support2Icon, support3Icon, support4Icon];

  return (
    <div className='welcome-container'>
      <div className='content-block-container'>
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{
            __html: t('franchise.cooperationTerms.title'),
          }}
        ></h2>
        <div
          className='advantages-container pre-wrap-text m-0'
          style={{ background: '#EDEFF0' }}
        >
          <div className='content-block width-80'>
            <p
              dangerouslySetInnerHTML={{
                __html: t('franchise.cooperationTerms.desc'),
              }}
            ></p>
          </div>
          <div className='packages-container'>
            <div className='adv-column'>
              <div className='note'>
                {t('franchise.packagesSection.forLittleCity')}
              </div>
              <div className='package-card light'>
                <h3 dangerouslySetInnerHTML={{ __html: cardLight.name }} />
                <div className='package-detail'>
                  <p>{profitLabel}</p>
                  <p
                    className='percentage'
                    dangerouslySetInnerHTML={{ __html: cardLight.profitValue }}
                  />
                </div>
                <div className='package-detail'>
                  <p>{depositLabel}</p>
                  <p
                    className='amount'
                    dangerouslySetInnerHTML={{ __html: cardLight.feeValue }}
                  />
                </div>
                <div className='package-detail'>
                  <p>{monthlyLabel}</p>
                  <p
                    className='amount'
                    dangerouslySetInnerHTML={{ __html: cardLight.royaltyValue }}
                  />
                </div>
              </div>
            </div>

            {/* --- Карточка Пакет 1 --- */}
            <div className='adv-column'>
              <div className='note note-line '>
                {t('franchise.packagesSection.forBigCity')}
              </div>
              <div className='packages-container__items'>
                <div className='package-card primary'>
                  <h3 dangerouslySetInnerHTML={{ __html: card1.name }} />
                  <div className='package-detail'>
                    <p>{profitLabel}</p>
                    <p
                      className='percentage'
                      dangerouslySetInnerHTML={{ __html: card1.profitValue }}
                    />
                  </div>
                  <div className='package-detail'>
                    <p>{depositLabel}</p>
                    <p
                      className='amount'
                      dangerouslySetInnerHTML={{ __html: card1.feeValue }}
                    />
                  </div>
                  <div className='package-detail'>
                    <p>{monthlyLabel}</p>
                    <p
                      className='amount'
                      dangerouslySetInnerHTML={{ __html: card1.royaltyValue }}
                    />
                  </div>
                  {card1.notes && <div className='notes'>{card1.notes}</div>}
                </div>

                {/* --- Карточка Пакет 2 --- */}
                <div className='package-card primary'>
                  <h3 dangerouslySetInnerHTML={{ __html: card2.name }} />
                  <div className='package-detail'>
                    <p>{profitLabel}</p>
                    <p
                      className='percentage'
                      dangerouslySetInnerHTML={{ __html: card2.profitValue }}
                    />
                  </div>
                  <div className='package-detail'>
                    <p>{depositLabel}</p>
                    <p
                      className='amount'
                      dangerouslySetInnerHTML={{ __html: card2.feeValue }}
                    />
                  </div>
                  <div className='package-detail'>
                    <p>{monthlyLabel}</p>
                    <p
                      className='amount'
                      dangerouslySetInnerHTML={{ __html: card2.royaltyValue }}
                    />
                  </div>
                  {card2.notes && <div className='notes'>{card2.notes}</div>}
                </div>

                {/* --- Карточка VIP --- */}
                <div className='package-card vip'>
                  <h3 dangerouslySetInnerHTML={{ __html: cardVip.name }} />
                  <div className='package-detail'>
                    <p>{profitLabel}</p>
                    <p
                      className='percentage'
                      dangerouslySetInnerHTML={{ __html: cardVip.profitValue }}
                    />
                  </div>
                  <div className='package-detail'>
                    <p>{depositLabel}</p>
                    <p
                      className='amount'
                      dangerouslySetInnerHTML={{ __html: cardVip.feeValue }}
                    />
                  </div>
                  <div className='package-detail'>
                    <p>{monthlyLabel}</p>
                    <p
                      className='amount'
                      dangerouslySetInnerHTML={{ __html: cardVip.royaltyValue }}
                    />
                  </div>
                  {cardVip.chips &&
                    cardVip.chips.map((chip, i2) => (
                      <div key={i2} className='notes vip-note'>
                        {chip}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className='support-content-container'>
            {/* Левая колонка */}
            <div className='left-column'>
              <h3
                className='block-title-h3'
                dangerouslySetInnerHTML={{ __html: data.packageTitle }}
              />
              <div className='left-right-row'>
                <ul className='list'>
                  {leftList1.map((item, i) => (
                    <li className='list-item' key={i}>
                      <span className='check-icon'>&#10003;</span>
                      {item}
                    </li>
                  ))}
                </ul>
                <ul className='list'>
                  {leftList2.map((item, i) => (
                    <li className='list-item' key={i}>
                      <span className='check-icon'>&#10003;</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Правая колонка */}
            <div className='right-column'>
              <h3
                className='block-title-h3'
                dangerouslySetInnerHTML={{ __html: data.packageSupportTitle }}
              />
              <div className='support-details'>
                {/* Первая строка из 2 блоков */}
                <div className='left-right-row'>
                  {supportBlocks.slice(0, 2).map((block, idx) => (
                    <div key={idx}>
                      <div className='support-item'>
                        <img
                          className='check-icon'
                          alt={`support ${block.icon}`}
                          src={icons[block.icon]}
                        />{' '}
                        <strong>{block.title}</strong>
                      </div>
                      <div className='package-detail m-0'>
                        <p>{block.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Вторая строка из 2 блоков */}
                <div className='left-right-row'>
                  {supportBlocks.slice(2, 4).map((block, idx) => (
                    <div key={idx}>
                      <div className='support-item'>
                        <img
                          className='check-icon'
                          alt={`support ${block.icon}`}
                          src={icons[block.icon]}
                        />{' '}
                        <strong>{block.title}</strong>
                      </div>
                      <div className='package-detail m-0'>
                        <p>{block.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CallToActionBlock = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: ctaIcon1,
      title: t('franchise.CallToAction.items.0.title'),
    },
    {
      icon: ctaIcon2,
      title: t('franchise.CallToAction.items.1.title'),
    },
    {
      icon: ctaIcon3,
      title: t('franchise.CallToAction.items.2.title'),
    },
  ];

  const faqBlocks = t('franchise.CallToAction.faq', { returnObjects: true });

  return (
    <div className='advantage-wrapper'>
      <div className='left-sidebar-background-img'></div>
      <div>
        <div className='title-call-to-action top-50'>
          <h2
            className='block-title-h2'
            style={{ color: '#fff' }}
            dangerouslySetInnerHTML={{
              __html: t('franchise.CallToAction.title'),
            }}
          ></h2>
        </div>
        <div className='advantages-container b-white pre-wrap-text m-0'>
          <div className='content-block width-80'>
            <p> {t('franchise.CallToAction.desc')}</p>
          </div>
          <div
            style={{ textAlign: 'center' }}
            className='all-included-item__title blue-b'
            dangerouslySetInnerHTML={{
              __html: t('franchise.CallToAction.subTitle'),
            }}
          ></div>
          <h2
            className='block-title-h2 top-50 bot-30'
            dangerouslySetInnerHTML={{
              __html: t('franchise.CallToAction.title2'),
            }}
          ></h2>
          <div className='bot-info-transfer '>
            {features.map((item, i) => (
              <div key={i} className='bot-info-transfer-item p-2'>
                <div className='bot-info-number-wrapper'>
                  <img
                    src={item.icon}
                    alt={`cta icon-${i}`}
                    className='all-included-item__icon'
                  />
                </div>
                <div>
                  <h3
                    className='all-included-item__title blue-b'
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                </div>
              </div>
            ))}
          </div>
          <a href='#orderForm' className='subscribe-button fh-btn top-50'>
            <img
              className='subscribe-button__img'
              src={mapPointImg}
              alt='rocket'
            />
            {t('franchise.CallToAction.reserveCityBtn')}
          </a>
          <h2
            className='block-title-h2 top-50 bot-30'
            dangerouslySetInnerHTML={{
              __html: t('franchise.CallToAction.title3'),
            }}
          ></h2>
          <SecondBlock blocks={faqBlocks} />
        </div>
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

const ReviewBlock = () => {
  const { t } = useTranslation();

  return (
    <div className='welcome-container'>
      <div className='review-block'>
        <div className='review-block__images'>
          <img
            className='review-block__triangle'
            src={triangleImg}
            alt='triangle'
          />
          <img className='review-block__man' src={manImg} alt='man' />
        </div>

        <div className='review-block__text'>
          <h3
            className='font-regular-20 font-gr'
            dangerouslySetInnerHTML={{ __html: t('franchise.review.name') }}
          />
          <p
            dangerouslySetInnerHTML={{ __html: t('franchise.review.profit') }}
          />
          <p
            dangerouslySetInnerHTML={{ __html: t('franchise.review.clients') }}
          />
        </div>
      </div>
    </div>
  );
};

const Franchise = () => {
  const { t } = useTranslation();

  return (
    <div className='hompage-wrapper'>
      <Helmet>
        <title>{t('SEO.franchisePageTitle')}</title>
        <meta name='description' content={t('SEO.franchisePageDescription')} />
        <link rel='canonical' href={`${t('SEO.url')}franchise`} />
        <meta property='og:title' content={t('SEO.franchisePageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.franchisePageDescription')}
        />
      </Helmet>
      <FranchiseTitle />
      <FranchiseAdvantage />
      <IncludedFeatures />
      <CooperationTerms />
      <CallToActionBlock />
      <ReviewBlock />
    </div>
  );
};

export default Franchise;
