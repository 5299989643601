import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import TgBotInfo from './hompage/TgBotInfo';
import { fetchExchangeRates } from '../api/get';
import { useEffect, useState } from 'react';

const USDTExchangeTable = () => {
  const { t, i18n } = useTranslation();
  const [rates, setRates] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await fetchExchangeRates();
        const russiaRates = response.data?.rates?.Russia || {};
        const lastUpdatedFromApi = response.data?.last_update;


        const filteredRates = {};
        Object.entries(russiaRates).forEach(([city, rateList]) => {
          const rubRate = rateList.find((rate) =>
            rate.currency_pair?.toLowerCase().includes('rub'),
          );
          if (rubRate) {
            filteredRates[city] = rubRate;
          }
        });

        setRates(filteredRates);
        setLastUpdated(lastUpdatedFromApi ? new Date(lastUpdatedFromApi) : new Date());
      } catch (err) {
        console.error('Ошибка при загрузке курсов:', err);
      }
    };

    fetchRates();
  }, [i18n.language]);

  const formatDate = (date) => {
    console.log("Date object:", date);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("Your time zone is:", timeZone);
    return new Intl.DateTimeFormat(i18n.language, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone
    }).format(date);
  };

  return (
    <div className='exchange-rates-table-container'>
      <table className='usdt-table'>
        <thead>
          <tr>
            <th>{t('usdtExchangeRates.tableHeaders.city')}</th>
            <th style={{ textAlign: 'center' }}>
              {t('usdtExchangeRates.tableHeaders.buy')} USDT
            </th>
            <th style={{ textAlign: 'center' }}>
              {t('usdtExchangeRates.tableHeaders.sell')} USDT
            </th>
          </tr>
        </thead>
        <tbody>
          {rates &&
            Object.entries(rates).map(([city, rate]) => (
              <tr key={city}>
                <td>{t(`cryptoCity.${city}.baseCity`)}</td>
                <td style={{ textAlign: 'center' }}>
                  {parseFloat(rate.buy_rate).toFixed(2)}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {parseFloat(rate.sell_rate).toFixed(2)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {lastUpdated && (
        <div className='table-footer'>
          {t('usdtExchangeRates.lastUpdated')}:&nbsp;
          <span className='updated-date'>
            <b>{formatDate(lastUpdated)}</b>
          </span>
        </div>
      )}
    </div>
  );
};

const ExchangeRates = () => {
  const { t, i18n } = useTranslation();
  const today = new Date();
  const formattedDate = new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(today);

  return (
    <div className='hompage-wrapper'>
      <Helmet>
        <title>{t('SEO.exchangeRatesPageTitle')}</title>
        <meta
          name='description'
          content={t('SEO.exchangeRatesPageDescription')}
        />
        <link rel='canonical' href={`${t('SEO.url')}usdt-rates`} />
        <meta property='og:title' content={t('SEO.exchangeRatesPageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.exchangeRatesPageDescription')}
        />
      </Helmet>
      <div className='advantage-wrapper'>
        <div className='left-sidebar-background-img'></div>
        <div className='advantages-container b-white pre-wrap-text m-0'>
          <h1
            className='block-title-h1'
            dangerouslySetInnerHTML={{
              __html: `${t('usdtExchangeRates.title')} ${formattedDate}`,
            }}
          ></h1>
          <p
            className='pink-b width-80'
            dangerouslySetInnerHTML={{ __html: t('usdtExchangeRates.desc') }}
          ></p>
          <USDTExchangeTable />
          <h2
            className='block-title-h2 top-50 bot-30'
            dangerouslySetInnerHTML={{
              __html: t('usdtExchangeRates.callToAction.title'),
            }}
          ></h2>
          <p
            className='width-80'
            dangerouslySetInnerHTML={{
              __html: t('usdtExchangeRates.callToAction.desc'),
            }}
          />
          <a
            href='tg://resolve?domain=DIMMAR_EX'
            className='subscribe-button fh-btn'
          >
            {t('companyInfo.firstBlockBtn')}
          </a>
          <div className='top-50'>
            <TgBotInfo />
          </div>
        </div>

        <div className='right-sidebar-background-img'></div>
      </div>
    </div>
  );
};

export default ExchangeRates;
